import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { init as initCommandAI } from 'commandbar';
import {
  COMMAND_AI_ORG_ID,
  COMMIT_SHA1,
  DATADOG_APP_ID,
  DATADOG_CLIENT_TOKEN,
  DEPLOYMENT_STAGE,
} from 'env';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import WebFont from 'webfontloader';
import { CommandAI } from './components/command-ai/CommandAI';
import { GatewayProvider } from './gateways/GatewayProvider';
import { Home } from './pages/home';
import { getLogger } from './shared/logger/Logger';
import { Auth0Provider } from './shared/providers/Auth0Provider';
import { MessagesProvider } from './shared/providers/MessagesProvider';
import './styles/core.scss';

const logger = getLogger();

if (DATADOG_CLIENT_TOKEN && DATADOG_APP_ID && DEPLOYMENT_STAGE) {
  datadogRum.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    applicationId: DATADOG_APP_ID,
    site: 'us3.datadoghq.com',
    version: COMMIT_SHA1,
    service: 'lynk-frontend',
    env: DEPLOYMENT_STAGE,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 1,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    silentMultipleInit: true,
    allowedTracingUrls: [/https:\/\/app\..*lynksupplychain\.com/],
  });

  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'us3.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'lynk-frontend',
    env: DEPLOYMENT_STAGE,
    version: COMMIT_SHA1,
    forwardConsoleLogs: ['warn', 'error'],
    forwardReports: 'all',
  });
  logger.info('Datadog RUM and Logs initialized');
} else {
  logger.warn('Datadog RUM not initialized');
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

if (COMMAND_AI_ORG_ID) {
  initCommandAI(COMMAND_AI_ORG_ID);
}

function App() {
  WebFont.load({
    google: {
      families: ['Inter:400,600,700,800,900'],
    },
  });

  // careful with provider order - some depend on others
  // Auth0Provider depends on Router
  // GatewayProvider depends on Auth0Provider
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <MessagesProvider>
          <Auth0Provider>
            <GatewayProvider>
              <CommandAI />
              <Home />
            </GatewayProvider>
          </Auth0Provider>
        </MessagesProvider>
      </Router>
    </QueryClientProvider>
  );
}

const root = createRoot(document.getElementById('root') as HTMLDivElement);
root.render(<App />);
